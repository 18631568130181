import React, { useCallback, useEffect, useMemo, useState } from 'react';
import firebase from "gatsby-plugin-firebase";
import DataTable from 'react-data-table-component';
import XLSX from 'xlsx';
import '../styles/list.css';
import excelIcon from '../assets/excel.svg';

export default function List() {
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);

  const handleExcelFile = useCallback((type) => {
    const fileName = type + '_list';
    const data = type === 'email' ? emails : phones;
    const jsonToXls = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new() // make Workbook of Excel
    XLSX.utils.book_append_sheet(wb, jsonToXls, fileName);
    XLSX.writeFile(wb, fileName + '.xlsx');
  }, [phones, emails])

  const columns = useMemo(() => [
    {
      name: 'Email',
      selector: 'value',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      right: true,
      cell: (row, index, column, id) => {
        // new Date(row.created_at)
        console.log(row, index)
        return <div>{row.created_at}</div>
      },
    },
  ], []);

  const phoneColumns = useMemo(() => [
    {
      name: 'Phone',
      selector: 'value',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'created_at',
      sortable: true,
      right: true,
      cell: (row, index, column, id) => {
        // new Date(row.created_at)
        console.log(row, index)
        return <div>{row.created_at}</div>
      },
    },
  ], []);

  useEffect(() => {
    firebase
      .firestore()
      .collection('email')
      .orderBy('created_at', 'desc')
      .onSnapshot(snapshot => {
        const data = snapshot.docs.map(item => {
          const data = item.data();
          return { ...data, created_at: new Date(data.created_at.toDate()).toLocaleString() };
        });
        console.log(data);
        setEmails(data);
      })

  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection('phone')
      .orderBy('created_at', 'desc')
      .onSnapshot(snapshot => {
        const data = snapshot.docs.map(item => {
          const data = item.data();
          return { ...data, created_at: new Date(data.created_at.toDate()).toLocaleString() };
        });
        console.log(data);
        setPhones(data);
      });
  }, []);

  return (
    <div id="main-wrapper">
      <h1>So'rovlar({emails.length + phones.length})</h1>
      <div className="d-flex">
        <div>
          <button type='button' className="export-btn" onClick={() => handleExcelFile('email')}><span>Export to <img src={excelIcon} alt="" /></span></button>
          <DataTable
            title={`Emails(${emails.length})`}
            columns={columns}
            data={emails}
            className="custom-table"
            pagination
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          />
        </div>
        <div>
          <button type='button' className="export-btn" onClick={() => handleExcelFile('phone')}><span>Export to <img src={excelIcon} alt="" /></span></button>
          <DataTable
            title={`Phones(${phones.length})`}
            columns={phoneColumns}
            data={phones}
            className="custom-table"
            pagination
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          />
        </div>

      </div>

    </div>
  )
}
